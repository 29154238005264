
html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter', sans-serif;
  }
}

/* Spacing | Global */
:root {
  --body-fonts: 'Inter', sans-serif;
}

#root {
  height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* Sections
     ========================================================================== */

body {
  margin: 0;
  font-family: var(--body-fonts);
  background-color: $Primary_background_clr;
  color: $Primary_text_clr;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px
}

h1 {
  font-size: 2.25rem;
  margin: 1.2rem 0 0 0;
  font-weight: 700;
  color: var(--dark);
  cursor: context-menu;
}

/* Grouping content
     ========================================================================== */

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0;
  border: 1px solid $Primary_border_clr;
}

pre {
  font-family: monospace, monospace;
  font-size: 1rem;
}

/* Scrollbar
     ========================================================================== */
// *::-webkit-scrollbar {
//   background-color: transparent;
//     width: 6px;
//     height: 6px;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: $Scrollbar_thumb_clr;
//   border-radius: 24px;
// }

/* Text-level semantics
     ========================================================================== */

a {
  background-color: transparent;
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: 'Inter', sans-serif;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
     ========================================================================== */
img {
  border-style: none;
}

/* Forms
     ========================================================================== */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;

  &:focus {
    outline: none;
  }
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

form{
  width: 100%;
}