// --- Theme Context
$Primary_clr: #12141D;
$Secondary_clr: #FF715B;
$White_clr: #FFFFFF;
$Gray_Primary_clr: rgba(255, 255, 255, 0.5);
$Gray_Secondary_clr: rgba(255, 255, 255, 0.1);


// --- Button => Primary, Secondary
$Primary_btn_bg_clr: $Secondary_clr;
$Primary_btn_border_clr: $Secondary_clr;
$Primary_btn_txt_clr: $White_clr;
$Primary_btn_icon_clr: $Primary_clr;
$Primary_btn_shadow_clr: rgba(0, 0, 0, 0.1);
$Secondary_btn_bg_clr: rgba(255, 255, 255, 0.1);
$Secondary_btn_border_clr: #292B33;
$Secondary_btn_txt_clr: rgba(255, 255, 255, 0.5);
$Secondary_btn_icon_clr: rgba(255, 255, 255, 0.5);
$Secondary_btn_shadow_clr: rgba(0, 0, 0, 0.1);

// --- Background => Primary, Secondary
$Primary_background_clr: $Primary_clr;
$Secondary_background_clr: rgba(255, 255, 255, 0.5);
$SimualGray_bg_clr: rgba(255, 255, 255, 0.1);
$Lightgray_bg_clr: rgba(255, 255, 255, 0.05);
$GrayShadow_bg_clr: #2A2B34;

// --- Text => Primary, Secondary
$Primary_text_clr: rgba(255, 255, 255, 0.5);
$Secondary_text_clr: $White_clr;
$Theme_text_clr: $Secondary_clr;

// --- Border
$Primary_border_clr: rgba(255, 255, 255, 0.05);

:export {

    // --- Theme Context   
    Primary_clr: $Primary_clr;
    Secondary_clr: $Secondary_clr;
    White_clr: $White_clr;
    Gray_Primary_clr: $Gray_Primary_clr;
    Gray_Secondary_clr: $Gray_Secondary_clr;

    // --- Button => Primary, Secondary
    Primary_btn_bg_clr: $Primary_btn_bg_clr;
    Primary_btn_border_clr: $Primary_btn_border_clr;
    Primary_btn_txt_clr: $Primary_btn_txt_clr;
    Primary_btn_icon_clr: $Primary_btn_icon_clr;
    Primary_btn_shadow_clr: $Primary_btn_shadow_clr;
    Secondary_btn_bg_clr: $Secondary_btn_bg_clr;
    Secondary_btn_border_clr: $Secondary_btn_border_clr;
    Secondary_btn_txt_clr: $Secondary_btn_txt_clr;
    Secondary_btn_icon_clr: $Secondary_btn_icon_clr;
    Secondary_btn_shadow_clr: $Secondary_btn_shadow_clr;

    // --- Background => Primary, Secondary
    Primary_background_clr: $Primary_background_clr;
    Secondary_background_clr: $Secondary_background_clr;
    SimualGray_bg_clr: $SimualGray_bg_clr;
    Lightgray_bg_clr: $Lightgray_bg_clr;
    GrayShadow_bg_clr: $GrayShadow_bg_clr;

    // --- Text => Primary, Secondary
    Primary_text_clr: $Primary_text_clr;
    Secondary_text_clr: $Secondary_text_clr;
    Theme_text_clr: $Theme_text_clr;

    // --- Border
    Primary_border_clr: $Primary_border_clr;

}