.menuopen {
  overflow: hidden !important;
}

.k-dialog-wrapper {
  z-index: 10;
}

.k-dialog-wrapper .k-dialog {
  background: #1e2028;
  padding: 0;
  border-radius: 10px;
  width: 800px !important;
  @media only screen and (max-width: 1023px) {
    width: 700px !important;
  }
  @media only screen and (max-width: 767px) {
    width: 350px !important;
  }
}
.k-window-title {
  padding: 0;
  margin: 0;
  position: unset;
  z-index: 999;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #ffffff;
}
.k-window-titlebar {
  padding: 0 0 12px 0;
  border: 0;
  background: #1e2028;
  margin: 24px 24px 0 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.k-window-actions {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
}
.k-button-icon {
  padding: 0;
  width: 100%;
  border-color: transparent !important;
  color: #fff;
  background-color: transparent !important;
  background-image: none;
}
.k-button-icon img {
  width: 100%;
  border-radius: 50%;
}
.k-window-content,
.k-prompt-container {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.k-window-content,
.k-prompt-container div {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 24px;
}

.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-timepicker {
  width: 100%;
}
.k-dateinput .k-dateinput-wrap {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px 0 0 5px;
  border: 0;
  padding: 12px;
}
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap {
  background: transparent;
  border: 0;
  color: transparent;
  box-shadow: none;
}
.k-input {
  border: 0;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 1);
  padding: 0;
  margin: 0;
  height: auto;
}
.k-datepicker .k-picker-wrap:hover,
.k-datepicker .k-picker-wrap.k-state-hover,
.k-timepicker .k-picker-wrap:hover,
.k-timepicker .k-picker-wrap.k-state-hover,
.k-datetimepicker .k-picker-wrap:hover,
.k-datetimepicker .k-picker-wrap.k-state-hover {
  background: transparent;
  box-shadow: none;
}

.k-datepicker .k-widget.k-dateinput,
.k-datetimepicker .k-widget.k-dateinput,
.k-timepicker .k-widget.k-dateinput {
  background: transparent;
  box-shadow: none;
}

.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 5px 5px 0;
}
.k-datepicker .k-picker-wrap:hover .k-select,
.k-datepicker .k-picker-wrap.k-state-hover .k-select,
.k-timepicker .k-picker-wrap:hover .k-select,
.k-timepicker .k-picker-wrap.k-state-hover .k-select,
.k-datetimepicker .k-picker-wrap:hover .k-select,
.k-datetimepicker .k-picker-wrap.k-state-hover .k-select {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.05);
}
.k-state-focused {
  background-color: transparent;
  box-shadow: none;
}
.k-dateinput-wrap {
  background-color: transparent;
  box-shadow: none;
}
.k-calendar-container {
  z-index: 9999;
}
.k-dropdown,
.k-dropdowntree {
  width: 100%;
}
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background: rgba(255, 255, 255, 0.05);
  padding: 4px 4px 4px 12px;
  border-radius: 5px;
  border: 0;
}
.k-list-container {
  z-index: 999;
  position: relative;
}
.k-dropdown .k-dropdown-wrap:hover,
.k-dropdowntree .k-dropdown-wrap:hover,
.k-dropdown .k-dropdown-wrap.k-state-hover,
.k-dropdowntree .k-dropdown-wrap.k-state-hover {
  border: 0;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  background-image: none;
}
.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-datepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-picker-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active {
  border: 0;
  color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.k-animation-container
  .k-animation-container-relative
  .k-calendar-container
  .k-group
  .k-reset
  .k-animation-container-shown {
  background: rgba(255, 255, 255, 0.1);
}
.k-popup .k-child-animation-container .k-slide-down-enter .k-slide-down-enter-active {
  box-shadow: none;
  background: rgba(255, 255, 255, 0.1);
}
.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active {
  border: 0;
  color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.k-dropdown .k-dropdown-wrap.k-state-focused,
.k-dropdowntree .k-dropdown-wrap.k-state-focused {
  border: 0;
  color: transparent;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: none;
}
.k-popup {
  background-color: #1e2028;
}

.k-calendar {
  background-color: #34363e;
}
.k-calendar .k-calendar-navigation {
  box-shadow: none;
  background-color: #34363e;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.k-calendar-navigation::before,
.k-calendar-navigation::after {
  box-shadow: none;
}
.k-calendar .k-calendar-navigation-highlight {
  background-color: rgba(255, 255, 255, 0.1);
}
.k-content {
  background-color: transparent;
}
.k-calendar-navigation::before,
.k-calendar-navigation::after {
  box-shadow: none;
}
.k-calendar-infinite .k-calendar-view::after,
.k-calendar-infinite .k-calendar-monthview::after {
  box-shadow: none;
}
.k-calendar .k-calendar-navigation li:hover {
  color: #fff;
}
.k-calendar .k-content .k-today {
  color: #fff;
}

.k-content {
  color: rgba(255, 255, 255, 0.5);
  z-index: 99;
}
.k-calendar-navigation .k-content li {
  color: rgba(255, 255, 255, 0.5);
}
.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #fff;
}

.k-calendar .k-footer .k-nav-today:hover,
.k-calendar .k-footer .k-nav-today:focus,
.k-calendar .k-calendar-header .k-today:hover,
.k-calendar .k-calendar-header .k-today:focus {
  color: rgba(255, 255, 255, 0.5);
}
.k-calendar-header .k-title {
  color: #fff;
  font-weight: 500;
}
.k-calendar .k-calendar-view th {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.k-calendar .k-state-selected.k-state-focused .k-link {
  box-shadow: none;
}
.k-calendar .k-state-selected .k-link {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
}
.k-calendar .k-state-selected .k-link:hover {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
}

.k-calendar .k-state-hover .k-link,
.k-calendar td:hover .k-link {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
}
.k-button-flat:hover,
.k-button.k-flat:hover,
.k-button.k-bare:hover,
.k-calendar-header .k-button:hover,
.k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal) > .k-button:hover,
.k-button-flat.k-state-hover,
.k-state-hover.k-button.k-flat,
.k-state-hover.k-button.k-bare,
.k-calendar-header .k-state-hover.k-button,
.k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal) > .k-state-hover.k-button,
.k-button-flat:active,
.k-button.k-flat:active,
.k-button.k-bare:active,
.k-calendar-header .k-button:active,
.k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal) > .k-button:active,
.k-button-flat.k-state-active,
.k-state-active.k-button.k-flat,
.k-state-active.k-button.k-bare,
.k-calendar-header .k-state-active.k-button,
.k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal) > .k-state-active.k-button,
.k-button-flat:hover:active,
.k-button.k-flat:hover:active,
.k-button.k-bare:hover:active,
.k-calendar-header .k-button:hover:active,
.k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal) > .k-button:hover:active,
.k-button-flat:hover.k-state-active,
.k-button.k-flat:hover.k-state-active,
.k-button.k-bare:hover.k-state-active,
.k-calendar-header .k-button:hover.k-state-active,
.k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal) > .k-button:hover.k-state-active {
  color: #fff;
}
.k-time-list-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: n;
}
.k-time-highlight {
  background-color: rgba(255, 255, 255, 0.1);
  border: 0;
}
.k-time-list-wrapper {
  background-color: #34363e;
  box-shadow: none;
}
.k-time-list-wrapper.k-state-focused::before,
.k-time-list-wrapper.k-state-focused::after {
  background-color: transparent;
}
.k-time-list::before,
.k-time-list::after {
  box-shadow: none;
}
.k-time-list .k-item:hover {
  color: #fff;
}
.k-time-header .k-title {
  color: rgba(255, 255, 255, 0.5);
}
.k-time-header .k-time-now {
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
}
.k-time-header .k-time-now:hover {
  color: #fff;
}
.k-button.k-primary {
  border-color: #ff715b;
  color: #ffffff;
  background-color: #ff715b;
}
.k-button {
  border-color: #1e2028;
  color: #fff;
  background-color: #1e2028;
  background-image: none;
}
.k-list .k-item,
.k-list .k-item.k-state-hover,
.k-list-optionlabel:hover,
.k-list-optionlabel.k-state-hover {
  padding: 4px 6px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
}
.k-list .k-item:hover,
.k-list .k-item.k-state-hover,
.k-list-optionlabel:hover,
.k-list-optionlabel.k-state-hover {
  color: #ffffff;
  background-color: #2a2b34;
}
.k-list .k-item:focus,
.k-list .k-item.k-state-focused,
.k-list-optionlabel:focus,
.k-list-optionlabel.k-state-focused {
  box-shadow: none;
}
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  color: #ffffff;
  background-color: #2a2b34;
  padding: 4px 10px;
  border-radius: 5px;
}
.k-list .k-item:hover.k-state-selected,
.k-list .k-item.k-state-hover.k-state-selected,
.k-list-optionlabel:hover.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected {
  color: #ffffff;
  background-color: #2a2b34;
}
.k-list {
  color: rgba(255, 255, 255, 0.5);
  margin: 10px 12px;
}
.k-button:hover,
.k-button.k-state-hover {
  border-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  background-image: none;
}
.k-animation-container-shown,
.k-animation-container > .k-popup {
  border-radius: 5px;
  // top: 8px;
  // left: -63%;
  // @media only screen and (max-width: 1279px) {
  //   top: 8px;
  //   left: -30%;
  // }
}
.k-dropdown-button {
  position: relative;
  z-index: 1;
}
.k-datetime-container .k-date-tab .k-datetime-buttongroup,
.k-datetime-container .k-date-tab .k-datetime-selector {
  background-color: transparent;
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group > input[type='radio']:checked + .k-button,
.k-button-group > input[type='checkbox']:checked + .k-button {
  border-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  background-image: none;
}
.k-button:focus,
.k-button.k-state-focused {
  box-shadow: none;
  border-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  background-image: none;
  box-shadow: none;
}
.k-button.k-primary {
  border-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  background-image: none;
}
.k-button.k-primary:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  background-image: none;
}
.k-button.k-primary:focus,
.k-button.k-primary.k-state-focused {
  box-shadow: none;
}
.k-button.k-primary:active,
.k-button.k-primary.k-state-active {
  border-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.05);
  background-image: none;
  box-shadow: none;
}
.k-button:active,
.k-button.k-state-active {
  border-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  background-image: none;
  box-shadow: none;
}
.k-i-calendar::before {
  content: url(../images/calendaricon.svg);
}
.k-icon {
  width: auto;
  height: auto;
}
.k-datetimepicker .k-link {
  width: 36px;
  height: 36px;
  background-color: #1e2028;
  border-radius: 3px;
  margin: auto 4px;
}
.k-i-arrow-s::before {
  content: url(../images/Sortvertical.svg);
}
.k-dropdown .k-dropdown-wrap .k-select,
.k-dropdowntree .k-dropdown-wrap .k-select {
  width: 36px;
  height: 36px;
  background-color: #1e2028;
  border-radius: 3px;
}

.upload_preview {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  border: 0;
  position: relative;
  overflow: hidden;
  margin-right: 28px;
  background-color: rgba(255, 255, 255, 0.05);
  img {
    width: 100%;
    height: 100%;
  }
}
.upload_box {
  margin-right: 0;
  width: auto;
  height: auto;
  border-radius: unset;
  background-color: transparent;
}
.k-dialog-wrapper .k-overlay {
  opacity: 0.8;
}
.btn-save-loading {
  position: relative;
  width: auto;
  display: inline-block;
  vertical-align: top;
}
.btn-save-loading.loading::before {
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.btn-save-loading.loading button {
  cursor: wait;
  background-color: #ffbcb1;
  border: 1px solid #ffbcb1;
}
.btn-save-loading.loading button::before {
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  margin-left: -10px;
}
.btn-save-loading.loading button::after {
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ball;
  margin-left: 10px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
.selected {
  background: #2a2b34;
  border-radius: 5px;
  padding: 4px 10px;
  color: #fff;
}
.successBox .statusText {
  color: #48e067 !important;
}
.socialicon {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  // span {
  //   margin-left: 10px;
  // }
}
.socialicon:before {
  content: '';
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: #fff;
  z-index: -1;
  left: 4px;
  top: 4px;
  border-radius: 100%;
}
.datetimewrapper {
  display: flex;
}
.timebox {
  width: 100%;
}
.Datebox {
  width: 100%;
  margin-left: 6px;
}
.timelock {
  margin: 0 8px !important;
  background-color: #2a2b34;
  border-radius: 2px;
  padding: 7px 7px;
}
.timelockmr {
  margin-right: 20px !important;
}
.timelockdot {
  margin: 0 !important;
}
.timelockml {
  margin-left: 0px !important;
}
.termsparagraph {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: inline-block;
}
.default .k-input {
  color: rgba(255, 255, 255, 0.3);
}
.termsmodalbox {
  margin-top: -10px;
}
.ProfileText {
  text-align: center;
}
.btn-submit {
  display: flex;
  justify-content: center;
}
.Mt-top {
  margin-top: 40px !important;
  margin-bottom: 40px;
}

// text editor
.txt-editor {
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    background-color: #292b33;
    border-color: transparent;
  }
  .ql-toolbar.ql-snow {
    .ql-formats {
      margin-right: 15px;
      border-right: 1px solid #ffffff10;
      padding-right: 15px;
      &:last-of-type {
        border-right: none;
      }
      @media only screen and (max-width: 767px) {
        margin-right: 0;
        border-right: 0;
        width: 100%;
        display: inline-block;
        padding: 3px 0;
      }
    }
    .ql-stroke {
      stroke: #ffffff;
    }
    .ql-fill,
    .ql-stroke.ql-fill {
      fill: #ffffff;
    }
    .ql-picker-label::before {
      color: #ffffff;
    }
  }
}

.txt-editor {
  .ql-snow .ql-picker-options {
    background-color: #1e2028;
    border-radius: 3px;
    color: #ffffff;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #1e2028;
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #ff715b;
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #ff715b;
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: #ff715b;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent;
  }
}
.txt-editor .ql-toolbar.ql-snow .ql-picker-label:hover::before {
  color: #ff715b;
}

.txt-editor {
  .ql-snow .ql-tooltip {
    background-color: #1e2028;
    border: 1px solid #ffffff10;
    box-shadow: none;
    color: #ffffff;
  }
  .ql-snow a {
    color: #ff715b;
  }
  .ql-snow .ql-tooltip input[type='text'] {
    background: #ffffff10;
    color: #fff;
    border-color: transparent;
  }
}

.txt-editor {
  .ql-container.ql-snow,
  .ql-editor {
    min-height: 120px;
  }
  .ql-toolbar.ql-snow {
    border-bottom: 1px solid #ffffff10;
  }
}

.txt-editor {
  .ql-editor.ql-blank::before {
    color: #ffffff50;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
  }
}
.quill.txt-editor {
  color: #ffffff;
}
.txt-editor .ql-snow a {
  color: #ffffff;
}
.txt-editor .ql-snow .ql-tooltip {
  left: 50% !important;
  transform: translate(-50%, -50%);
  min-height: 40px;
  border: 1px solid #ffffff50;
  background-color: #292b33;
}
.ql-snow .ql-tooltip a.ql-action::after {
  margin-left: 0;
  text-transform: uppercase;
}
.ql-snow .ql-tooltip input[type='text'] {
  margin-right: 8px;
}
.herotext ul {
  list-style: unset;
  margin-left: 19px;
  font-size: 16px;
  line-height: 24px;
}
.herotext ul li {
  padding: 0px 0 2px 0;
}
.herotext ol li {
  list-style: decimal;
  margin-left: 19px;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 0 2px 0;
}
.herotext a {
  color: #ff715b;
}
.ql-syntax {
  background: #23241f;
  border-radius: 4px;
  display: inline-block;
  padding: 4px 6px;
  margin: 7px 0;
}
blockquote {
  margin-bottom: 10px;
  border-left: 3px solid #fff;
  padding-left: 15px;
  font-size: 16px;
  line-height: 20px;
}
em {
  font-style: italic;
}
.ql-size-huge {
  font-size: 2.5em;
  line-height: initial;
}
.ql-size-small {
  font-size: 0.75em;
  font-family: initial;
}
.ql-size-large {
  font-size: 1.5em;
  line-height: initial;
}
.subjects-item:focus {
  color: #fff;
}
